import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import DcodeMarkerHammer from "../../components/sections/DcodeMarkerHammer/DcodeMarkerHammer";
import DcodeMarkerHammer2 from "../../components/sections/DcodeMarkerHammer2/DcodeMarkerHammer2";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToTechnologyButton from "../../components/shared/ButtonBackTo/BackToTechnologyButton";


const Dmh = () => {
    const routes = getRoutes();
    const parentTitle = routes.Technology.pageTitle;
    const title = routes.TechnologyDMH.pageTitle;
    const sectionAnchor = getRoutes().Technology.sections.markers.anchor

    return (
        <Layout>
            <SEO title={title}/>

            <Breadcrumbs
                title={title}
                parentTitles={[parentTitle]}
                parentRoutes={[routes.Technology]}
                sectionAnchors={[sectionAnchor]}
            />

            <DcodeMarkerHammer/>

            <DcodeMarkerHammer2/>

            <BackToTechnologyButton sectionAnchor={sectionAnchor} blackBg/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default Dmh;
